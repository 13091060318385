<template>
  <div class="ecmain" id="main" style="width:100%;height:100%">

  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([ RadarChart, CanvasRenderer]);

export default {
  props:{
    score:Object
  },
  data(){
    return {
      sc:this.score
    }
  },
  computed:{
    scs(){
      return this.score
    }
  },
  methods:{
    getparams(){
      console.log(this.score)
      return this.score
    }
  },
  mounted(){
      var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    var option;
    this.sc = this.getparams()

    option = {
      radar: {
        // shape: 'circle',
        indicator: [
          { name: "寓意内涵", max: 100,min:60 },
          { name: "音律动听", max: 100 ,min:60},
          { name: "八字五行", max: 100 ,min:60},
          { name: "三才五格", max: 100,min:60 },
          { name: "重名分析", max: 100,min:60 },
          { name: "生肖分析", max: 100 ,min:60},
        ],
      },
      series: [
        {

          type: "radar",
          symbol:"none",
          data: [
            {
              value: [this.scs.one, this.scs.two, this.scs.three, this.scs.four, this.scs.five, this.scs.sex],
              areaStyle:{
                color:"#B95353"
              },
              lineStyle:{
               color:"#B95353" 
              }
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  }
}
</script>


<style>
.ecmain{
  width: 80%;
  height: 80%;
}
</style>