<template>
	<div class="NewOrder" id="agentTitle">
		<div class="header">
			<span @click="back" class="returnback"></span>
			{{nameinfo.base.name}} 起名结果
		</div>
		<div class="center" style="margin-top:52px">
			<div class="tipbox">
				<img id="btn_pre_name" @click="prename" src="//cdn.zhiyileiju.cn/name_nav.png"
					style="position:absolute;width:25px;height:24px;left: -40px;transform: rotateY(180deg);top: calc(54% - 20px);z-index:999" />

				<ul class="ordernavbox ulbox">
					<li v-for="(value, index) in nameinfo.base.pinyin" :key="value">
						<p>{{ value }}</p>
						<span>{{ nameinfo.base.name[index] }}</span>
						<i>[{{ nameinfo.base.asktaoval[index] }}]</i>
					</li>
				</ul>

				<img id="btn_next_name" @click="nextname" src="//cdn.zhiyileiju.cn/name_nav.png"
					style="position:absolute;width:25px;height:24px;right: -40px;top: calc(54% - 20px);z-index:999" />
			</div>
		</div>
		<div v-if="showsx" class="goodname">
			<dl>
				<dt>佳名赏析</dt>
				<dd v-for="item in nameinfo.base.means" :key="item.key">
					【{{ item.key }}】：{{ item.value }}。
				</dd>
			</dl>
		</div>
		<div class="pf">
			<dl>
				<dt class="center">综合评分</dt>
				<dd class="center">
					<span class="score">{{ nameinfo.score.avg }}</span><span class="des">分(大吉)</span>
				</dd>
			</dl>
			<div class="center">
				<div style="width:280px;height:220px">
					<charts :score="nameinfo.score" />
				</div>
			</div>
		</div>
		<div class="pingfenProgress">
			<ul class="pfbox">
				<li>
					<p>
						寓意内涵<b class="percent" :data-percent="nameinfo.score.one"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.one }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_ccd2ac" style="width:94.27%;"></i>
					</div>
				</li>
				<li>
					<p>
						音律动听<b class="percent" :data-percent="nameinfo.score.two"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.two }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_acd2b0" style="width:94.36%;"></i>
					</div>
				</li>
				<li>
					<p>
						八字五行<b class="percent" :data-percent="nameinfo.score.three"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.three }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_b0d9e1" style="width: 97.23%;"></i>
					</div>
				</li>
			</ul>
			<ul class="pfbox">
				<li>
					<p title="五行评分">
						三才五格<b class="percent" :data-percent="nameinfo.score.four"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.four }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_b2bbd8" style="width:98.16%;"></i>
					</div>
				</li>
				<li>
					<p>
						重名分析<b class="percent" :data-percent="nameinfo.score.five"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.five }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_d3bcd4" style="width:98.0%;"></i>
					</div>
				</li>
				<li>
					<p>
						生肖分析<b class="percent" :data-percent="nameinfo.score.sex"
							style="color: rgb(20, 183, 165);">{{ nameinfo.score.sex }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_edc3ce" style="width:92.32%;"></i>
					</div>
				</li>
			</ul>
		</div>

		<div class="goodname">
			<dl>
				<dt class="mt-15">五行分析</dt>
				<Paipan :sex="nameinfo.sex == 1 ? '男' : '女'" :data="nameinfo.eight_chara" />
				<WuxingCate :data="nameinfo.ask_tao" :qk_score_ratio="nameinfo.ask_tao.qk_score_ratio"
					style="margin-top:15px" class="mt-15" />
				<div class="txt " style="margin-top:15px">
					同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
				</div>
				<div class="txt ">
					异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
				</div>
				<div class="txt ">综合旺弱判定</div>
				<div class="txt ">
					同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
				</div>
				<dt class="mt-15">五行含量</dt>
				<WuxingHl />
				<dt>喜用神</dt>
				<dd class="lh2e">
					经过对八字、五行力量以及季节力量的综合分析，本命局取
					<span v-for="(value, key) in nameinfo.ask_tao.asktao_result['喜用']" :key="value"
						style="color:#b95353">{{ key }}</span>为喜用五行，起名时建议带平衡喜用神 的汉字。
				</dd>
			</dl>
			<dl>
				<dd>
					【<span class="color-red">{{ nameinfo.xname }}</span>】 姓氏是祖辈传承而来不参与喜用神分析。
				</dd>
				<dd v-for="(value, index) in nameinfo.base.mname" :key="value">
					【<span class="color-red">{{ value }}</span>】 汉字五行是：<span class="color-red">{{
            nameinfo.base.asktaoval[index + 1]
          }}</span>, <span class="color-red">有利</span>于喜用神。
				</dd>

				<dd style="font-size:12px;color:#979797;line-height:1.5em">
					注：依传统国学理论，起名不是按五行缺什么补什么，而是分析后的喜用神。
				</dd>
			</dl>
			<dl>
				<dt>汉字意形</dt>
				<dd>
					<div class="descbox">
						<table class="tbbox">
							<tbody>
								<tr word="1">
									<th>姓名</th>
									<td v-for="item in nameinfo.hanzimean.xm" :key="item">
										<div class="zi">{{ item }}</div>
									</td>
								</tr>
								<tr pinying="1">
									<th>拼音</th>
									<td v-for="item in nameinfo.hanzimean.pinyin" :key="item">
										{{ item }}
									</td>
								</tr>
								<tr kangxibh="1">
									<th>康熙笔画</th>
									<td v-for="item in nameinfo.hanzimean.kxbihua" :key="item">
										{{ item }}
									</td>
								</tr>
								<tr wuxing="1">
									<th>五行</th>
									<td v-for="(value,index) in nameinfo.hanzimean.wx" :key="index">
										{{ value }}
									</td>
								</tr>
								<tr bushou="1">
									<th>繁体</th>
									<td v-for="item in nameinfo.hanzimean.ft" :key="item">
										{{ item }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</dd>
			</dl>
			<!-- <dl>
        <dt>美好寓意</dt>
        <dd>
          <span
            style=" margin-right : 5px"
            class="color-red"
            v-for="item in nameinfo.tag"
            :key="item"
            >{{ item }}、</span
          >
        </dd>
      </dl> -->
			<!-- <dl>
        <dt>宝宝小名</dt>
        <dd class="lh2e">
          宝宝生肖属相为虎，为您的宝宝推荐以下可爱好听的 小名：
          <span v-for="(value, idnex) in nameinfo.bbxm" :key="value"
            >{{ value }}
            <template v-if="idnex + 1 != nameinfo.bbxm.length"
              >、
            </template> </span
          >。
        </dd>
        <dd
          style="font-size:12px;color:#979797;ling-height:1.5em;margin-top:-10px"
        >
          注：好听的名字是和谐优美、让人愉悦的。但起名更要结合字
          义等多维度考量，不能单看音律。
        </dd>
      </dl> -->
			<dl>
				<dt>三才五格</dt>
				<dd class="lh2e wgbox">
					<ul class="wg-list">
						<li>
							<div class="wg-unit">
								外格<span class="wg-num">{{
                  nameinfo.scwg.fiveGirl["外"]
                }}</span>
							</div>
						</li>
						<li scwg-name="1">
							<div v-for="(value, index) in nameinfo.hanzimean.xm" :key="value" class="wg-unit">
								<strong>{{ value }}</strong><span class="wg-num">{{
                  nameinfo.hanzimean.kxbihua[index]
                }}</span>
							</div>
						</li>
						<li>
							<div class="wg-unit">
								天格<span class="wg-num">{{
                  nameinfo.scwg.fiveGirl["天"]
                }}</span>
							</div>
							<div class="wg-unit">
								人格<span class="wg-num">{{
                  nameinfo.scwg.fiveGirl["人"]
                }}</span>
							</div>
							<div class="wg-unit">
								地格<span class="wg-num">{{
                  nameinfo.scwg.fiveGirl["地"]
                }}</span>
							</div>
						</li>
					</ul>
					<table class="tbtxt">
						<colgroup>
							<col width="26%" />
						</colgroup>
						<tbody>
							<tr>
								<td>【<strong>{{nameinfo.base.name}}</strong>】</td>
								<td>
									<p>
										五格：天格<strong>{{ nameinfo.scwg.fiveGirl["天"] }}</strong>，地格<strong>{{ nameinfo.scwg.fiveGirl["地"] }}</strong>，外格<strong>{{ nameinfo.scwg.fiveGirl["外"] }}</strong>，人格<strong>{{ nameinfo.scwg.fiveGirl["人"] }}</strong>，总格<strong>{{ nameinfo.scwg.fiveGirl["总"] }}</strong>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</dd>
			</dl>
			<dl>
				<dt>五格数理</dt>
				<dd>
					<ul>
						<li class="shuli">
							<div class="sut">
								天格<span>{{ nameinfo.scwg.fiveGirl["天"] }}</span>
							</div>
							<div>
								[影响]12岁前的先天运势以及先天遗传，影响不大，若不理想不必计较
							</div>
							<div>[总论]{{ nameinfo.scwg.fiveNum["天"].mean }}</div>
						</li>
						<li class="shuli">
							<div class="sut">
								人格<span>{{ nameinfo.scwg.fiveGirl["人"] }}</span>
							</div>
							<div>
								[影响]24-48岁人生颠峰期运势以及个性、才华及事业运，影响一生运势
							</div>
							<div>[总论]{{ nameinfo.scwg.fiveNum["人"].mean }}</div>
						</li>
						<li class="shuli">
							<div class="sut">
								地格<span>{{ nameinfo.scwg.fiveGirl["地"] }}</span>
							</div>
							<div>
								[影响]36岁前的青年运势以及家庭夫妻、子女田宅，影响基础运
							</div>
							<div>[总论]{{ nameinfo.scwg.fiveNum["地"].mean }}</div>
						</li>
						<li class="shuli">
							<div class="sut">
								外格<span>{{ nameinfo.scwg.fiveGirl["外"] }}</span>
							</div>
							<div>
								[影响]36-48岁的中年运势以及社交、朋友、工作环境等，影响后天的机遇
							</div>
							<div>[总论]{{ nameinfo.scwg.fiveNum["外"].mean }}</div>
						</li>
						<li class="shuli">
							<div class="sut">
								总格<span>{{ nameinfo.scwg.fiveGirl["总"] }}</span>
							</div>
							<div>
								[影响]48岁后的中晚年运势以及财富、收入，影响人生最终的成就
							</div>
							<div>[总论]{{ nameinfo.scwg.fiveNum["总"].mean }}</div>
						</li>
					</ul>
				</dd>
			</dl>
			<dl>
				<dt>三才配置</dt>
				<dd class="nameDetail">
					<div class="scImg">
						<div class="top">
							<p class="scItem"><i class="gre">天</i>才</p>
							<p class="wxT">
								[<i>{{ nameinfo.scwg.sanCai["天"] }}</i>]
							</p>
						</div>
						<div class="nameCenter">
							<ul class="duyin">
								<li v-for="item in nameinfo.hanzimean.pinyin" :key="item">
									{{ item }}
								</li>
							</ul>
							<ul class="name">
								<li v-for="item in nameinfo.hanzimean.xm" :key="item">
									{{ item }}
								</li>
							</ul>
						</div>
						<div class="left">
							<p class="scItem"><i class="di">地</i>才</p>
							<p class="wxT">
								[<i>{{ nameinfo.scwg.sanCai["天"] }}</i>]
							</p>
						</div>
						<div class="right">
							<p class="scItem"><i class="ren">人</i>才</p>
							<p class="wxT">
								[<i>{{ nameinfo.scwg.sanCai["人"] }}</i>]
							</p>
						</div>
					</div>
					<p class="lh2e">
						三才配置：<span v-for="(value, key) in nameinfo.scwg.sanCai" :key="key"
							class="color-red">{{ value }}</span>
						&nbsp;此配置者发展之路艰难险阻颇多，皆因
						基础不稳，无法获得帮扶，要想成功，唯有付出比别人更多 的努力。
					</p>
				</dd>
				<dd style="font-size:12px;color:#979797;ling-height:1.5em;margin-top:-10px">
					注：以上内容来自传统民俗文化分析，结果仅供参考。
				</dd>
			</dl>
			<dl>
				<dt>姓名重复</dt>
				<dd>
					<div class="descbox">
						<!-- <div class="repeatbox">
                        <div class="repeatbg" style="height:30.58%;"></div>
                        <p>30.58%</p>
                    </div> -->
						<div class="groupbox">
							<!--
                            重复率低：i-group-1
                            重复率中：i-group-2
                            重复率高：i-group-3
                        -->
							<i class="i-group  i-group-1"></i>
							<ul>
								<li>
									<em>{{ nameinfo.base.name_repeat }}</em>个叫【<strong>{{ nameinfo.base.name }}</strong>】
								</li>
								<li><em>1000万</em>个人当中</li>
							</ul>
						</div>
						<table class="tbtxt">
							<colgroup>
								<col width="24%" />
							</colgroup>
							<tbody>
								<tr>
									<td>【<strong>重复率</strong>】</td>
									<td>
										<p>
											<strong>{{ nameinfo.base.name }}</strong>姓名重名率极低，是很好的名字，可放心使用。
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</dd>
			</dl>
			<dl>
				<dt>生肖分析</dt>
				<dd>
					<img style="width:100%" :src="'https://cdn.zhiyileiju.cn/' + shu[nameinfo.base.sx] + '.jpg'" />
				</dd>
			</dl>
		</div>
	</div>
</template>

<script>
	import axios from "../utils/request";
	import charts from "../components/charts.vue";
	import Paipan from "../components/Paipan.vue";
	import WuxingCate from "../components/WuxingCate.vue";
	import WuxingHl from "../components/WxHanLg.vue";
	import router from "../router";
	import {
		Dialog
	} from "vant";
	import report from "../utils/stat";

	export default {
		components: {
			charts,
			Paipan,
			WuxingCate,
			WuxingHl,
		},
		data() {
			return {
				shu: {
					狗: "gou",
					猴: "hou",
					虎: "hu",
					鸡: "ji",
					龙: "long",
					马: "ma",
					牛: "niu",
					蛇: "she",
					兔: "tu",
					羊: "yang",
					猪: "zhu",
					鼠: "shu"
				},
				nameinfo: {
					base: {
						id: 29,
						pinyin: ["lín", "wèi"],
						name: "",
						mname: "渭",
						asktaoval: "木水",
						means: [{
							key: "渭",
							value: "字意：水名，即渭河，发源于甘肃省渭源，流到陕西省汇同泾河入黄河。",
						}, ],
						sx: "牛",
						xz: "巨蟹座",
						name_repeat: 139,
					},
					eight_chara: {
						id: 76,
						bazi: [{
							y: "辛丑"
						}, {
							m: "乙未"
						}, {
							d: "甲子"
						}, {
							h: "甲子"
						}],
						zao: [{
							y: "金土"
						}, {
							m: "木土"
						}, {
							d: "木水"
						}, {
							h: "木水"
						}],
						nayin: [{
								y: "壁上土"
							},
							{
								m: "沙中金"
							},
							{
								d: "海中金"
							},
							{
								h: "海中金"
							},
						],
						ml: "木",
					},
					ask_tao: {
						id: 76,
						benming: "木",
						ori_qk_score: {
							金: 3,
							木: 4,
							水: 13,
							火: 2,
							土: 6
						},
						qk_score_weight: {
							金: 0.5,
							木: 0.7,
							水: 0.5,
							火: 1.5,
							土: 2
						},
						qk_score: {
							金: 1.5,
							木: 2.8,
							水: 6.5,
							火: 3,
							土: 12
						},
						qk_score_ratio: {
							金: "6%",
							木: "11%",
							水: "25%",
							火: "12%",
							土: "46%",
						},
						ww_score_ratio: {
							木: "30%",
							水: "70%"
						},
						dw_score_ratio: {
							金: "9%",
							火: "18%",
							土: "73%"
						},
						asktao_result: {
							喜用: {
								木: 2.8,
								水: 6.5
							},
							忌用: {
								金: 1.5,
								火: 3,
								土: 12
							},
						},
						ww_ratio_dw: "56%",
						asktao_style: null,
						asktao_kami: null,
					},
					score: {
						avg: 98,
						one: 96,
						two: 98,
						three: 99,
						four: 98,
						five: 96,
						sex: 98,
					},
					yl: {
						val: "阳平,去声",
						mean: "音律由低到高，尾音去声，循序渐进，利于呼叫，不错的名字音律。",
					},
					tag: [
						"清新 地位",
						"擅长 清晰",
						"品德",
						"和睦 兴盛",
						"高雅 美德",
						"荣耀 和谐",
						"强健 高贵",
						"旺盛 雄伟",
					],
					hanzimean: {
						xm: "林渭",
						ft: "林渭",
						pinyin: ["lín", "wèi"],
						kxbihua: ["8", "13"],
						wx: "木水",
					},
					bbxm: ["渭渭", "牛牛", "小渭", "旺渭", "渭子"],
					scwg: {
						fiveGirl: {
							天: 9,
							地: 14,
							人: 21,
							总: 21,
							外: 2
						},
						fiveNum: {
							天: {
								val: 9,
								asktao: "水",
								mean: "（大成之数）大成之数，蕴涵凶险，或成或败，难以把握。 （凶）",
							},
							地: {
								val: 14,
								asktao: "火",
								mean: "（破兆）家庭缘薄，孤独遭难，谋事不达，悲惨不测。 （凶）",
							},
							人: {
								val: 21,
								asktao: "木",
								mean: "（明月中天）光风霁月，万物确立，官运亨通，大搏名利。 （吉）",
							},
							总: {
								val: 21,
								asktao: "木",
								mean: "（明月中天）光风霁月，万物确立，官运亨通，大搏名利。 （吉）",
							},
							外: {
								val: 2,
								asktao: "木",
								mean: "（两仪之数）两仪之数，混沌未开，进退保守，志望难达。 （凶）",
							},
						},
						sanCai: {
							天: "水",
							地: "火",
							人: "木"
						},
					},
					order_status: 300,
					order_id: null,
					name_ids: {
						"1": "1,4,11,12,29,35,41,47,49,52,73,77,86",
						"2": "",
					},
					sex: 1,
					xname: "林",
				},
				nameid: ""
			};
		},
		computed: {
			WW() {
				return Object.keys(this.nameinfo.ask_tao.ww_score_ratio);
			},
			DW() {
				return Object.keys(this.nameinfo.ask_tao.dw_score_ratio);
			},
			WWrd() {
				return Object.keys(this.nameinfo.ask_tao.ww_score_ratio).reduce(
					(a, b) =>
					this.nameinfo.ask_tao.qk_score[a] + this.nameinfo.ask_tao.qk_score[b]
				);
			},
			DWrd() {
				let a = 0;
				Object.keys(this.nameinfo.ask_tao.dw_score_ratio).forEach((key) => {
					a = a + this.nameinfo.ask_tao.qk_score[key];
				});
				return a;
			},
			name_ids() {
				let name_ids;
				if (this.nameinfo.base.name.length == 3) {
					name_ids = this.nameinfo.name_ids[2];
				} else {
					name_ids = this.nameinfo.name_ids[1];
				}
				return name_ids.split(",");
			},
			currentNameIndex() {
				return this.name_ids.indexOf(this.nameid + "");
			},
			showsx() {
				let a = false
				this.nameinfo.base.means.forEach(item => {
					if (item.value.indexOf('暂无') == -1) {
						a = true
					}
				})
				return a;
			}
		},
		async beforeCreate() {
			let orderdid = this.$route.params.id;
			let data;
			try {
				data = await axios.post("/orderNameDetail", {
					order_id: this.$route.params.id,
					name_id: this.$route.params.nameid,
					type: this.$route.params.type
				});
				if (data.data.data.order_status != 300) {
					Dialog.confirm({
						message: "当前订单未支付",
						showCancelButton: false,
					}).then(() => {
						router.replace({
							name: "Index"
						}); // on confirm
					});
				}
			} catch (err) {
				Dialog.confirm({
					message: "获取姓名详情失败,请稍后重试",
					showCancelButton: false,
				}).then(() => {
					router.replace({
						name: "Index"
					});
				});
			}
		},
		created() {
			this.orderid = this.$route.params.id;
			this.nameid = this.$route.params.nameid;
			this.type = this.$route.params.type;
			this.getInfo(this.orderid, this.nameid);
			report("v1_namededail");
		},
		mounted() {
			document
				.getElementById("agentTitle")
				.scrollIntoView({
					block: "start"
				});
		},
		methods: {
			async getInfo(id, nameid) {
				let data = await axios.post("/orderNameDetail", {
					order_id: id,
					name_id: nameid,
					type: this.type
				});
				let nameinfo = data.data.data;
				// nameinfo.base.name = nameinfo.base.name.split("");
				nameinfo.base.pinyin = nameinfo.base.pinyin.split(",");
				// nameinfo.base.asktaoval = nameinfo.base.asktaoval.split(",");

				let temp = [];
				Object.keys(nameinfo.base.means).forEach((key) => {
					temp.push({
						key,
						value: nameinfo.base.means[key],
					});
				});
				
				let bh = nameinfo.hanzimean.kxbihua.slice(0, -1);
				let py = nameinfo.hanzimean.pinyin.slice(0, -1);

				nameinfo.hanzimean.kxbihua = bh.split("#");
				nameinfo.hanzimean.pinyin = py.split("#");

				nameinfo.base.means = temp;

				this.nameinfo = nameinfo;
				this.nameid = nameinfo.base.id

			},
			back() {
				this.$router.back(-1)
			},
			async prename() {
				let index
				if (this.currentNameIndex == 0) {
					index = 99;
				} else {
					index = this.currentNameIndex
				};
				await this.getInfo(this.orderid, this.name_ids[index - 1])

				// router.replace({
				//   name: "resultdetail",
				//   params: {
				//     id: this.orderid,
				//     nameid: this.name_ids[this.currentNameIndex - 1],
				//   },
				// });
			},
			async nextname() {
				let index
				if (this.currentNameIndex == 99) {
					index = 0;
				} else {
					index = this.currentNameIndex
				}

				await this.getInfo(this.orderid, this.name_ids[index + 1])

				// router.replace({
				//   name: "resultdetail",
				//   params: {
				//     id: this.orderid,
				//     nameid: this.name_ids[this.currentNameIndex + 1],
				//   },
				// });
			},
		},
	};
</script>

<style lang="less" scoped>
	.header {
		position: fixed;
		top: 0;
		z-index: 100;
		color: white;
		height: 52px;
		width: 100vw;
		line-height: 52px;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		background: #525252;
	}

	.returnback {
		display: inline-block;
		width: 50px;
		height: 50px;
		background: url("//cdn.zhiyileiju.cn/return_back.png") no-repeat center;
		background-size: 25px;
		position: fixed;
		left: 10px;
		top: 0;
		z-index: 100;
	}

	.groupbox {
		font-size: 13px;
		text-align: center;

		ul {
			position: relative;
			display: inline-block;
			vertical-align: middle;
		}

		li {
			padding: 5px 15px;

			em {
				margin-right: 5px;
				font-weight: bold;
				font-size: 25px;
				color: #b95353;
			}

			&:last-child {
				border-top: 1px solid #ddd;
			}
		}

		strong {
			color: #b95353;
		}
	}

	.i-group {
		display: inline-block;
		vertical-align: middle;
		width: 125px;
		height: 125px;
		background: url(//cdn.zhiyileiju.cn/groupsfdsdf.png) no-repeat center top / 100% auto;

		&.i-group-1 {
			background-position: center top;
		}

		&.i-group-2 {
			background-position: center center;
		}

		&.i-group-3 {
			background-position: center bottom;
		}
	}

	
	.nameDetail .scImg {
		width: 100%;
		height: 300px;
		margin: 30px 0;
		background: url(//cdn.zhiyileiju.cn/qwewewqew.png) no-repeat center 40px;
		background-size: 250px;
	}


	.nameDetail .nameCenter {
		text-align: center;
		padding-top: 25px;
		font-size: 0;
	}

	.nameDetail .nameCenter .name li,
	.nameDetail .nameCenter .duyin li {
		display: inline-block;
		min-width: 60px;
		height: 30px;
		font-size: 32px;
		color: #333;
		text-align: center;
		line-height: 30px;
		font-weight: bold;
	}

	.nameDetail .nameCenter .duyin li {
		font-size: 24px;
		font-weight: normal;
		letter-spacing: 1px;
		line-height: 30px;
		height: 30px;
	}

	.nameDetail .scImg .wxT,
	.nameDetail .scImg .wxL,
	.nameDetail .scImg .wxR {
		color: #b9a687;
		text-align: center;
	}

	.nameDetail .scImg .scItem {
		color: #666;
		font-weight: 500;
		display: block;
		text-align: center;
	}

	.nameDetail .scImg .gre,
	.nameDetail .scImg .di,
	.nameDetail .scImg .ren {
		display: inline-block;
		width: 65px;
		height: 65px;
		line-height: 65px;
		text-align: center;
		color: #fff;
		border-radius: 50px;
		font-size: 35px;
		margin-right: 4px;
	}

	.nameDetail .gre {
		background-color: #4dbfef;
	}

	.nameDetail .di {
		background-color: #84ddb6;
	}

	.nameDetail .ren {
		background-color: #fed165;
	}

	.nameDetail .scImg .left,
	.nameDetail .scImg .right {
		display: inline-block;
		margin-top: 0px;
	}

	.nameDetail .scImg .left {
		padding-right: 2%;
		width: 48%;
	}

	.nameDetail .scImg .right {
		padding-left: 8%;
		width: 39%;
	}

	.nameDetail .scImg .nameCenter .name {
		font-size: 0;
		line-height: 40px;
		letter-spacing: 0;
	}
</style>

<style lang="less" scoped>
	@highlightColor: #b95353;
	@siteColor: #b95353;

	@import "../style/main.css";

	.wgbox {
		padding: 15px;
		text-align: center;

		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			border-left: 1px solid @siteColor;
		}

		.wg-unit {
			margin: 10px 0;

			&::before {
				display: inline-block;
				vertical-align: middle;
				width: 15px;
				height: 1px;
				content: "";
				background-color: @siteColor;
			}
		}

		.wg-num {
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			color: @highlightColor;
			background-color: @siteColor;
			color: #fff;
		}

		h4 {
			margin-top: 25px;
			font-weight: bold;
			text-align: center;
		}
	}

	* {
		margin: 0;
		padding: 0;
	}

	.tbtxt {
		margin-top: 15px;
		width: 100%;
		font-size: 13px;
		text-align: left;

		th,
		td {
			vertical-align: top;
			padding: 5px;
		}

		strong {
			color: @siteColor;
		}

		.tb-caption {
			font-weight: bold;
			text-align: left;
			color: #000;

			&:before {
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
				width: 3px;
				height: 3px;
				content: "";
				background-color: #333;
				border-radius: 50%;
			}
		}
	}

	.shuli {
		font-size: 14px;
		font-family: Microsoft YaHei;
		line-height: 2em;
		color: #525252;
		margin-bottom: 8px;

		.sut {
			font-size: 14px;
			color: #020202;
			line-height: 28px;

			span {
				margin: 0 5px;
			}
		}
	}

	.descbox {
		font-size: 14px;
		text-align: justify;

		strong {
			color: @highlightColor;
		}

		.tbbox {
			font-size: 12px;

			td {
				padding: 7px 5px;
			}

			&.tb-wxhl {
				td {
					text-align: left;
				}

				tfoot {
					font-size: 12px;
					color: #111;

					th {
						text-align: center;
						color: @highlightColor;
						background-color: #fff;
					}
				}
			}
		}

		.desc-p {
			font-size: 14px;
			text-align: justify;
		}

		&.descbox-sx {
			h5 {
				font-weight: bold;
			}

			p {
				padding: 5px 0;
			}
		}
	}

	.zi {
		margin: 0 auto;
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 18px;
		color: #111;
		text-align: center;
		background: url(//cdn.zhiyileiju.cn/NewNameBjk.png) no-repeat center / cover;
	}

	.tbbox {
		width: 100%;
		font-size: 13px;
		text-align: center;
		border-collapse: collapse;
		border-spacing: 0;
	}

	.tbbox th,
	.tbbox td {
		border: 1px solid #ccc;
	}

	.tbbox th {
		padding: 5px 0;
		color: #b95353;
		background-color: #f2eedf;
	}

	.tbbox td {
		padding: 10px;
	}

	.tbbox tfoot {
		font-size: 12px;
		text-align: left;
		color: #999;
	}
</style>

<style lang="less">
	.tipbox {
		clear: both;
		width: auto !important;
		display: inline-block;
		position: relative;
	}

	.NewOrder {
		width: 100%;
		overflow: hidden;
		background-color: #fff;
		min-height: 100vh;
	}

	.NewOrdCon {
		width: 100%;
		overflow: hidden;
		position: relative;
		padding: 0 12px;
	}



	.goodname {
		width: 100%;
		box-sizing: border-box;
		overflow: hidden;
		padding: 0 15px;
		color: #000;
		font-size: 16px;
		text-align: left;
		margin-bottom: 15px;

		dt {
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #b95353;
			line-height: 20px;
			margin-bottom: 14px;
			margin-top: 15px;
		}

		dd {
			margin-left: 0;
			font-size: 14px;
			color: #525252;
			line-height: 20px;
			margin-bottom: 12px;
		}
	}
</style>

<style lang="less" scoped>
	.pf {
		dt {
			font-size: 16px;
			font-weight: bold;
			color: #020202;
			margin-bottom: 15px;
		}

		dd {
			margin-bottom: 20px;
		}

		.score {
			color: #b95353;
			font-size: 36px;
			font-weight: bold;
			position: relative;
			line-height: 0.8em;
			align-self: flex-end;
		}

		.des {
			font-size: 14px;
			align-self: flex-end;
			line-height: 1em;
		}
	}

	.pfbox {
		display: flex;
		justify-content: space-between;
	}

	.pingfenProgress {
		width: calc(100% - 30px);
		margin-left: 15px;
	}

	.pingfenProgress li {
		margin: 5px 0;
		font-size: 10px;
		color: #666;
		text-align: center;
		width: 32%;
	}

	.pingfenProgress li p {
		padding-bottom: 5px;
	}

	.pingfenProgress li b {
		padding-left: 3px;
		color: #333;
		font-size: 13px;
	}

	.bfb,
	.bfb i {
		height: 10px;
		border-radius: 10px;
		display: block;
	}

	.bfb {
		background: #e4e4e4;
	}

	.back_ccd2ac {
		background: #7fe4af;
	}

	.back_acd2b0 {
		background: #9cccf6;
	}

	.back_b0d9e1 {
		background: #ffd394;
	}

	.back_b2bbd8 {
		background: #d7c68f;
	}

	.back_d3bcd4 {
		background: #d692eb;
	}

	.back_edc3ce {
		background: #b9db7b;
	}

	.txt {
		font-size: 14px;
		font-weight: 400;
		color: #020202;
		line-height: 2em;
		padding: 0;
	}

	.lh2e {
		line-height: 2em;
	}

	.color-red {
		color: #b95353;
	}
</style>
